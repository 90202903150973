<script setup>
  const search = window.location.search.split('=')
  const targetUrl = (search.length >= 2 && search[0] === '?from') ? search[1] : null
  const offerStatus = process.env.VUE_APP_OFFER_STATUS === 'true' 
</script>

<template>
  <div class="hk-502-main">
    <article>
      <div class="hk-502-content-container">

        <div class="hk-502-content">
          <div>
            <h1>Temporarily unavailable.</h1>
          </div>

          <div>
            Website <a :href="targetUrl">{{ targetUrl }}</a> is temporarily unavailable.
          </div>

          <div v-if="!targetUrl" :style="{marginTop: '1rem'}">
            <i>Please come back later.</i>
          </div>

          <div v-if="offerStatus" class="hk-502-status-holder">
            You can check status of the service on <a href="https://status.hkuboservices.sk" target="_blank">status.hkuboservices.sk</a>
          </div>

          <div v-if="targetUrl" class="hk-502-link-holder">
            <a :href="targetUrl" class="link-button">Try again</a>
          </div>
        </div>

        <div class="hk-502-icon">
          <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 10H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v4a2 2 0 0 1-2 2h-2"></path>
            <path d="M6 14H4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-4a2 2 0 0 0-2-2h-2"></path>
            <path d="M6 6h.01"></path>
            <path d="M6 18h.01"></path>
            <path d="m13 6-4 6h6l-4 6"></path>
          </svg>
        </div>

      </div>

      <div>
        <p>HKubo</p>
      </div>
    </article>
  </div>
</template>

<script>
export default {
  name: 'ModuleType502',
}
</script>

<style scoped lang="scss">
@import '@/styles/variables';
.hk-502-content-container {
  display: flex;
  justify-content: space-between;
  align-items: start;
}
.hk-502-icon {
  @media screen and (max-width: 449.99px){
    svg {
      display: none;
    }
  }
  @media screen and (min-width: 450px){
    svg {
      width: 6rem;
      height: 6rem;
      stroke: darken($text_label, 20%);
    }
  }
}
.hk-502-link-holder {
  margin: 1.8rem 0;
}
.hk-502-status-holder {
  margin: 1rem 0;
}
.hk-502-main {
  display: flex;
  justify-content: center;
  align-items: center;

  article {
    width: min(80vw, 650px);
    padding: 1.8rem;
    padding-bottom: 0.8rem;
    border: 1px solid $text_label;
    border-radius: 1rem;
  }

  a {
    transition: all 0.2s;
    &:hover {
      color: $accent_main;
      transition: all 0.2s;
    }
  }
  h1 {
    font-size: 2rem;
    font-family: Syne, Arial;
    margin-top: 0;
  }
  p {
    margin: 0;
    text-align: center;
    color: $text_label;
  }
  i {
    color: $text_label;
  }
  .link-button {
    background: transparent;
    border: 2px solid $accent_main;
    border-radius: 0.8rem;
    color: $accent_main;
    padding: 0.5rem 1rem 0.6rem;
    font-size: 1.1rem;
    transition: all .2s;
    text-decoration: none;

    &:hover {
      color: $text_main;
      border-color: #fff;
      transition: all .2s;
      cursor: pointer;
    }
  }
}
</style>
